import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/89a06d9/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const DoDontExample = makeShortcode("DoDontExample");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3 {...{
      "id": "content-design-can-make-or-break-an-online-experience-always-strive-for-writing-that-is-clear-concise-and-on-brand"
    }}>{`Content design can make or break an online experience. Always strive for writing that is clear, concise, and on-brand`}</h3>
    <AnchorLinks mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">Capitalization</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Verb tense</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Active and passive voice</AnchorLink>
      <AnchorLink mdxType="AnchorLink">First and second person</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Formal vs. casual tone</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Can, may, and might</AnchorLink>
    </AnchorLinks>
    <h2 {...{
      "id": "capitalization"
    }}>{`Capitalization`}</h2>
    <p>{`Use sentence-style capitalization in text and for all text elements in the UI except for proper nouns. Sentence style capitalizes only the first word of each sentence.`}</p>
    <h4 {...{
      "id": "examples"
    }}>{`Examples:`}</h4>
    <ul>
      <li parentName="ul">{`Cloud Foundry apps`}</li>
      <li parentName="ul">{`Business models`}</li>
      <li parentName="ul">{`Creating Boolean expressions`}</li>
      <li parentName="ul">{`Planning network architectures`}</li>
      <li parentName="ul">{`Carbon Design System`}</li>
    </ul>
    <h2 {...{
      "id": "active-and-passive-voice"
    }}>{`Active and passive voice`}</h2>
    <p>{`The `}<em parentName="p">{`active voice`}</em>{` is direct and punchy, and emphasizes the subject of the sentence. The subject clearly “acts upon” the verb (hence, “active”). For example, “John ate the apple.” In situations where either voice will work, generally choose the active voice for more directness.`}</p>
    <Row mdxType="Row">
  <Column offsetLg="4" colMd="4" colLg="4" mdxType="Column">
    <DoDontExample square correct label="Do use active voice when appropriate." text="Next, the admin configures access privileges." mdxType="DoDontExample" />
  </Column>
  <Column colMd="4" colLg="4" mdxType="Column">
    <DoDontExample square label="Don’t use passive voice when active voice will suffice." text="Next, access privileges are configured by the admin." mdxType="DoDontExample" />
  </Column>
    </Row>
    <p>{`The `}<em parentName="p">{`passive voice`}</em>{`, on the other hand, flips the construction so that the subject is secondary to the verb and object (hence, “passive”). Often, the subject is not even included in the sentence. For example, “`}<em parentName="p">{`The apple was eaten by John`}</em>{`” or just “`}<em parentName="p">{`The apple was eaten`}</em>{`.” Only sentences that contain direct objects can be constructed in the passive voice. Thus, “`}<em parentName="p">{`John ate`}</em>{`” cannot be constructed passively.`}</p>
    <p>{`The passive voice makes for a more natural tone in certain use cases. For example, if the true subject of the sentence is a system, and the human is secondary, passive voice can be acceptable.`}</p>
    <Row mdxType="Row">
  <Column offsetLg="4" colMd="4" colLg="4" mdxType="Column">
    <DoDontExample square correct label="Do use passive voice when appropriate." text="The database needs to be rebooted." mdxType="DoDontExample" />
  </Column>
  <Column colMd="4" colLg="4" mdxType="Column">
    <DoDontExample square label="Don’t use active voice when passive voice is more appropriate." text="Someone needs to reboot the database." mdxType="DoDontExample" />
  </Column>
    </Row>
    <h2 {...{
      "id": "first-and-second-person"
    }}>{`First and second person`}</h2>
    <p>{`Engage your readers by using second person `}<strong parentName="p">{`(you, your)`}</strong>{` where appropriate. First person `}<strong parentName="p">{`(I, we, our)`}</strong>{` focuses on the writer rather than the audience, which is rarely appropriate in UI or technical contexts. Avoid the first person unless you have a compelling reason to use it.`}</p>
    <p>{`One exception to this is in the case of possessive adjectives in the UI. You can use first person in headings or labels that are very specific to the user or customer data, such as “My Account” or “My Usage.” In explanatory text for the heading or label, however, use second person. For example, `}<em parentName="p">{`“Your usage is calculated from the first day of the month.”`}</em></p>
    <h2 {...{
      "id": "formal-vs-casual-tone"
    }}>{`Formal vs. casual tone`}</h2>
    <p>{`While a more formal tone is often appropriate for technical and business writing, a more casual tone is becoming increasingly accepted (and expected) in UI and supporting materials.`}</p>
    <ul>
      <li parentName="ul">{`Don’t be afraid to use `}<strong parentName="li">{`contractions`}</strong>{`. When they fit the context and improve flow, go for it.`}</li>
      <li parentName="ul">{`Beginning sentences with `}<strong parentName="li">{`and, but, or so`}</strong>{` is not always forbidden. When it allows for shorter, scannable sentences, they can be used. Do not overuse these devices, especially in instructional content. For example, `}<em parentName="li">{`“You can deploy an app to the cloud during lunch hour. But it’s not required.”`}</em>{` works for `}<em parentName="li">{`Discover, Try, Buy`}</em>{` experiences.`}</li>
      <li parentName="ul">{`It’s acceptable to use `}<strong parentName="li">{`questions in headings`}</strong>{`. In both UIs and documentation, questions can be used to further conversational style. But don’t overuse them, as they can add to noise. Make sure headings that use questions are meaningful. In a UI, questions can also be used in a confirmation prompt in a dialog box. For example, `}<em parentName="li">{`“Do you want to close without saving?”`}</em></li>
      <li parentName="ul">{`Use `}<strong parentName="li">{`exclamation marks`}</strong>{` only positively, not negatively. Make sure you use no more than one exclamation mark in a context, such as a single window or a single Docs topic.`}</li>
    </ul>
    <Row mdxType="Row">
  <Column offsetLg="4" colMd="4" colLg="4" mdxType="Column">
    <DoDontExample square correct label="Do use exclamation points for positive messages." text="Your IBM Cloud account is ready!" mdxType="DoDontExample" />
  </Column>
  <Column colMd="4" colLg="4" mdxType="Column">
    <DoDontExample square label="Don’t use exclamation points for negative messages" text="You have reached your usage limit!!" mdxType="DoDontExample" />
  </Column>
    </Row>
    <br />
    <h4 {...{
      "id": "terms-of-politeness"
    }}>{`Terms of politeness`}</h4>
    <p>{`Often overused, these terms can convey the wrong tone for technical material, and are not regarded the same way in all cultures. Use terms such as “please” and “thank you” carefully.`}</p>
    <Row mdxType="Row">
  <Column offsetLg="4" colMd="4" colLg="4" mdxType="Column">
    <DoDontExample square correct label="Do Use terms of politeness in a UI only when the user is being inconvenienced." text="Indexing might take a few minutes. Please wait." mdxType="DoDontExample" />
  </Column>
  <Column colMd="4" colLg="4" mdxType="Column">
    <DoDontExample square label="Don’t Use terms of politeness superfluously." text="Please create a subscription account to get full access to the catalog." mdxType="DoDontExample" />
  </Column>
    </Row>
    <h2 {...{
      "id": "can-may-and-might"
    }}>{`Can, may, and might`}</h2>
    <h4 {...{
      "id": "terms-of-ability"
    }}>{`Terms of ability`}</h4>
    <p>{`These terms are often misused. Remember, “can” implies ability, and “may” implies permission (and sometimes uncertainty).`}</p>
    <Row mdxType="Row">
  <Column offsetLg="4" colMd="4" colLg="4" mdxType="Column">
    <DoDontExample square correct label="Do use ‘can’ to express ability." text="You can use the command line interface to update your app." mdxType="DoDontExample" />
  </Column>
  <Column colMd="4" colLg="4" mdxType="Column">
    <DoDontExample square label="Don’t use ‘may’ when you mean ‘can.’" text="You may use the command line interface to update your app." mdxType="DoDontExample" />
  </Column>
    </Row>
    <br />
    <h4 {...{
      "id": "terms-of-possibility"
    }}>{`Terms of possibility`}</h4>
    <p>{`These terms can also be confusing. Remember, when either “may” or “might” will work, generally go with “might” to avoid confusion with the multiple meanings of “may.”`}</p>
    <Row mdxType="Row">
  <Column offsetLg="4" colMd="4" colLg="4" mdxType="Column">
    <DoDontExample square correct label="Do use ‘might’ to clarify possibility." text="You might need more advanced features when integrating with another app." mdxType="DoDontExample" />
  </Column>
  <Column colMd="4" colLg="4" mdxType="Column">
    <DoDontExample square label="Don’t use ‘may’ when ‘might’ will work." text="You may need more advanced features when integrating with another app." mdxType="DoDontExample" />
  </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      